<template>
  <div>
    <!-- <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH> -->
    <TABS :tabChange="onCallbackTab" :list="tabsArr"></TABS>
    <TABLE :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

    <add-check @close="_loadData" :data='data' v-if="isShow" ></add-check>
  </div>
</template>

<script>
// import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import addCheck from './components/addCheck' // 新增学习稽查
import { getLearnCheck, delLearnCheck, getLearnCheckDetail } from '@/api/safety'
export default {
  name: 'SafetyLearnCheck',
  components: {
    TABS,
    TABLE,
    PAGE,
    addCheck,
  },
  data() {
    return {
      searchArr: [
        {
          name: '分类',
          type: 'picker',
          options: [],
          key: 'type',
        },
        {
          name: '标题',
          type: 'input',
          options: [],
          key: 'title',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加稽查',
          func: 'openAddCheck',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'title',
          label: '标题',
          width: '300',
        },
        {
          prop: 'type',
          label: '分类名称',
          width: '300',
        },
        {
          prop: 'remarks',
          label: '图片',
          width: '800',
          type: 'img',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: 'editCheck',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeContent',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
      },
      isShow: false,
      data: {},
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.isShow = false
      this.dataArr = []
      this.data = {}
      getLearnCheck(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
          item.imgs = item.files
        })
        this.dataArr = result.data
      })
    },

    openAddCheck: function() {
      // this.$refs.addC.open()
      this.isShow = true
    },

    // 获取稽查详情
    editCheck: function({ id }) {
      getLearnCheckDetail(id).then(res => {
        const { result } = res
        this.data = {
          files: result.files,
          s_id: result.s_id,
          id: result.id,
        }
        this.isShow = true
      })
    },

    // 删除学习稽查
    removeContent: function({ id }) {
      delLearnCheck(id).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 收到 tabs 回调
    onCallbackTab: function ({ item, index }) {
      this[item.func]()
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
