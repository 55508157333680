<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="45%"
    :title="`${isEdit ? '编辑' : '添加'}学习稽查`"
    :visible.sync="isShow"
  >
    <div class="all-input">
      <div style="width: 70% !important" class="item-input">
        <div class="label"><span class="red">*</span>学习内容：</div>
        <el-select class="big" v-model="formData.s_id" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="width: 80% !important" class="item-input file">
        <div class="label"><span class="red">*</span>上传文件：</div>
        <el-upload
          ref="upload"
          action='https://jsonplaceholder.typicode.com/posts/'
          :file-list="fileList"
          :auto-upload="false"
          :on-change='getFile'
          :on-remove='delFile'
          :http-request='submitUpload'
        >
          <el-button slot="trigger" size="small" type="primary"
            >选取文件</el-button
          >
          <el-button
            style="margin-left: 10px"
            size="small"
            type="success"
            :loading='isLoad'
            @click="upload"
            >上传到服务器</el-button
          >
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addLearnCheck, updLearnCheck, getLCSelect } from '@/api/safety'
import { uploadFile } from '@/api/common'
import { debounce } from '@/utils/util'
export default {
  name: 'addCheck',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isShow: true, // 是否打开弹窗
      isLoad: false,
      options: [],
      fileList: [],
      formData: {
        s_id: '', // 标题
        files: '', // 说明
      },
      isEdit: false,
      files: [], // 上传文件列表
      fileIndex: 0, // 文件当前索引
      isSave: true, // 是否保存
      backup: [],
      list: [],
    }
  },
  created() {
    this._loadSelect()
    if (this.data.files) {
      this.isEdit = true
      this.data.files.forEach(s => {
        this.list.push(s)
        this.fileList.push({
          name: s,
          url: s,
        })
        this.files.push({
          status: 'success',
          name: s,
          url: s,
        })
      })

      this.formData = {
        s_id: this.data.s_id,
        files: this.data.files.join(','),
        id: this.data.id,
      }
    }
  },
  methods: {
    _loadSelect: function () {
      getLCSelect().then((res) => {
        this.options = res.result
      })
    },
    upload: debounce(async function () {
      this.fileIndex = 0
      this.list = []
      this.files = this.files.map(s => {
        if (s.raw) {
          s = s.raw
        }
        return s
      })
      this.backup = []
      if (this.files.length) this.isLoad = true
      for await (const f of this.files) {
        this.submitUpload(f)
      }
    }, 1000),
    submitUpload: function(f) {
      // console.log(f, '：这是对应的')
      return new Promise(resolve => {
        if (f.status && f.status === 'success') {
          this.backup.push(f)
          this.list.push(f)
          this.fileIndex++

          if (this.fileIndex === this.files.length) {
            this.isSave = true
            this.fileList = this.backup
            this.isLoad = false
          } else this.isSave = false

          resolve('success')
        } else {
          uploadFile(f).then(res => {
            const { result } = res
            this.backup.push({
              name: f.name,
              url: result.filePath,
            })
            this.list.push({
              name: f.name,
              url: result.filePath,
            })
            this.fileIndex++

            if (this.fileIndex === this.files.length) {
              this.isSave = true
              this.fileList = this.backup
              this.isLoad = false
            } else this.isSave = false
            resolve('success')
          })
        }
      })
    },

    // 获取文件
    getFile: function(file) {
      const uid = this.files.find(s => {
        return s.uid === file.raw.uid
      })
      if (!uid) {
        this.files.push(file)
        this.isSave = false
      }
      this.fileIndex = 0
    },

    // 获取删除后的文件列表
    delFile: function(file, files) {
      this.files = files
      const name = file.name

      this.list.forEach((s, i) => {
        if (s === name || s.name === name) {
          this.list.splice(i, 1)
        }
      })
    },

    close: function (isLoad) {
      this.$emit('close')
    },

    submit: function() {
      if (!this.formData.s_id) {
        this.$toast('请选择学习内容')
        return false
      } else if (!this.list.length) {
        this.$toast('请上传图片')
        return false
      }
      // console.log(this.fileList, this.list, '这最后的文件')
      const list = []
      this.list.forEach(s => {
        list.push(s.url || s)
      })
      this.formData.files = list.join(',')

      if (!this.isEdit) this.saveCheck()
      else this.editCheck()
    },

    saveCheck: function () {
      addLearnCheck(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close(true)
          this.loadChange && this.loadChange()
        }
      })
    },

    editCheck: function () {
      updLearnCheck(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close(true)
          this.loadChange && this.loadChange()
        }
      })
    },

    clearFormData: function () {
      for (const key in this.formData) {
        this.formData[key] = ''
      }
      this.fileList = []
      this.files = []
    },
  },
}
</script>

<style lang="stylus" scoped>
.all-input {
  justify-content: space-between;

  .file {
    align-items: flex-start;
  }
}
</style>
