import { render, staticRenderFns } from "./addCheck.vue?vue&type=template&id=32933081&scoped=true&"
import script from "./addCheck.vue?vue&type=script&lang=js&"
export * from "./addCheck.vue?vue&type=script&lang=js&"
import style0 from "./addCheck.vue?vue&type=style&index=0&id=32933081&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32933081",
  null
  
)

export default component.exports